import ChatIcon from './vender/chat.svg?url'
import DocIcon from './vender/doc.svg?url'
import DashboardIcon from './vender/dashboard.svg?url'
import CollapseIcon from './vender/shouqi.svg?url'
import ExpandIcon from './vender/expand.svg?url'
import AddIcon from './vender/add.svg?url'
import SendIcon from './vender/send.svg?url'
import StopIcon from './vender/stop.svg?url'
import LockIcon from './vender/lock.svg?url'
import PhoneIcon from './vender/phone.svg?url'
import LoadingIcon from './vender/loading.svg?url'
import CloseIcon from './vender/close.svg?url'
import VoiceIcon from './vender/voice.svg?url'
import CopyIcon from './vender/copy.svg?url'
import SvgIcon from './vender/svg.svg?url'
import FlowIcon from './vender/flow.svg?url'
import ChevronDownIcon from './vender/chevron-down.svg?url'
import CheckCricleIcon from './vender/checkCricle.svg?url'
import AlertCricleIcon from './vender/alertCricle.svg?url'
import CloseCricleIcon from './vender/closeCricle.svg?url'
import InfoCricleIcon from './vender/infoCricle.svg?url'
import ArrowRight from './vender/arrowRight.svg?url'
import ArrowLeftIcon from './vender/arrowLeft.svg?url'
import LogoutIcon from './vender/logout.svg?url'
import UserIcon from './vender/user.svg?url'
import MessageIcon from './vender/message.svg?url'
import EmailIcon from './vender/email.svg?url'
import EyeIcon from './vender/eye.svg?url'
import EyeOffIcon from './vender/eyeOff.svg?url'
import AccountIcon from './vender/account.svg?url'
import EmailLockIcon from './vender/emaiLock.svg?url'
import LockCheckIcon from './vender/lockCheck.svg?url'
import DownloadIcon from './vender/download.svg?url'
import CodeIcon from './vender/code.svg?url'
import ExcelIcon from './vender/excel.svg?url'
import PdfIcon from './vender/pdf.svg?url'
import DocSearchIcon from './vender/doc-search.svg?url'


export {
  ChatIcon,
  DocIcon,
  DashboardIcon,
  CollapseIcon,
  ExpandIcon,
  AddIcon,
  SendIcon,
  StopIcon,
  LockIcon,
  PhoneIcon,
  LoadingIcon,
  CloseIcon,
  VoiceIcon,
  CopyIcon,
  SvgIcon,
  FlowIcon,
  ChevronDownIcon,
  CheckCricleIcon,
  AlertCricleIcon,
  CloseCricleIcon,
  InfoCricleIcon,
  ArrowRight,
  ArrowLeftIcon,
  LogoutIcon,
  UserIcon,
  MessageIcon,
  EmailIcon,
  EyeIcon,
  EyeOffIcon,
  AccountIcon,
  EmailLockIcon,
  LockCheckIcon,
  DownloadIcon,
  CodeIcon,
  ExcelIcon,
  PdfIcon,
  DocSearchIcon
}
